<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="iq-card">
        <div class="iq-card-body p-0">
          <div class="iq-edit-list">
            <ul class="iq-edit-profile nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
              <li class="flex-fill p-0">
                <a class="nav-link active" data-toggle="pill" href="#profile">
                  {{cvProfileInfo}}
                </a>
              </li>
              <li class="flex-fill p-0 disabled">
                <a class="nav-link" data-toggle="pill" href="#about">
                  {{cvAbout}}
                </a>
              </li>
              <li class="flex-fill p-0 disabled">
                <a class="nav-link" data-toggle="pill" href="#contactsocial">{{cvContact}}</a>
              </li>
              <li class="flex-fill p-0 disabled">
                <a class="nav-link" data-toggle="pill" href="#edit-roles">
                  {{ cvEditRoles }}
                </a>
              </li>
              <li class="flex-fill p-0 disabled">
                <a class="nav-link" data-toggle="pill" href="#manage-users">
                  {{ cvManageUsers }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="iq-edit-list-data">
        <div class="tab-content">
          <div class="tab-pane fade active show" id="profile" role="tabpanel">
            <b-card class="mb-4 px-3 pb-3">
              <div>
                <ValidationObserver ref="clubForm" >
                  <b-form>
                    <template v-if="userData.user_role === 'USERROLE11111'"> <!-- Admin Role -->
                      <div class="col-md-12 mb-3 mt-3 p-0">
                        <label>Create As a</label>
                        <multiselect
                          v-model="vmCreateAs"
                          :options="cvCreateAsOption">
                        </multiselect>
                      </div>
                      <div class="col-md-12 mb-3 mt-3 p-0" v-if="vmCreateAs === 'University'">
                        <label>Select University</label>
                        <multiselect
                          v-model="vmClubFormData.organisation"
                          :options="cvUnivOptions"
                          placeholder="Select University"
                          label="org_name"
                          @search-change="organisationList($event)"
                          track-by="org_id"
                          :custom-label="orgNameWithCityCountry">
                        </multiselect>
                      </div>
                    </template>
                    <div class="col-md-12 mb-3 mt-3 p-0">
                      <ValidationProvider
                        name="Club Name"
                        rules="required|max:50"
                        v-slot="{ validate, errors }"
                        ref="clubProvider">
                        <label>Club name</label>
                        <input v-model="vmClubFormData.club_name" @input="validate" name="club_name" type="text" class="form-control" :placeholder="cvClubNameLabel" required />
                        <span class="text-danger">
                          <span v-if="vmClubFormData.club_name && errors[0]"> Club name may not be more than 50 characters. </span>
                          <span v-else> {{ errors[0] }} </span>
                        </span>
                      </ValidationProvider>
                    </div>
                    <div>
                      <input v-model="plus_or_not" type="checkbox" name="gideplus" style="margin-right:5px" />
                      <label for="gideplus" style="color:red">Is this Club Gide Plus ?</label>
                    </div>
                  </b-form>
                </ValidationObserver>
              </div>
              <div>
                <h4>
                  Profile Pic
                </h4>
                <hr>
                <b-form>
                  <div class="form-group row align-items-center">
                    <div class="col-md-12">
                      <div class="profile-img-edit border" style="height: 150px; width: 150px; border-radius: 50%;">
                        <img v-if="vmProfilePic" :src="vmProfilePic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: contain; overflow: hidden; border-radius: 50%; " />
                        <i
                          v-else
                          class="fas fa-users d-flex justify-content-center align-items-center rounded-circle "
                          style="font-size: 5rem; height:100%; color: #c1c1c1;">
                        </i>
                        <label class="p-image" >
                          <i class="ri-pencil-line upload-button" style="cursor:pointer;"></i>
                          <input class="file-upload" type="file" accept="image/png,image/jpeg,image/jpg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0; pointer-events: none; "  @change="openImageDialog($event, 'Profile Pic')" />
                        </label>
                      </div>
                    </div>
                    <span class="text-danger" ref="proPicError"></span>
                  </div>
                  <span></span>
                </b-form>
              </div>
              <div class="mt-4">
                <h4>
                  Cover Pic
                </h4>
                <hr>
                <b-form>
                  <div class="form-group row align-items-center">
                    <div class="col-md-12">
                      <div class="profile-img-edit border" style="height: 250px; width: 100%; border: 1px solid #c1c1c1; border-radius: 5px;">
                        <img v-if="vmCoverPic" :src="vmCoverPic" alt="cover-pic " style="height: 100%; width: 100%; object-fit: contain; overflow: hidden;" />
                        <i v-else class="fas fa-users d-flex justify-content-center align-items-center" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                        <label class="p-image">
                          <i class="ri-pencil-line upload-button" style="cursor: pointer"></i>
                          <input class="file-upload" type="file" accept="image/png,image/jpeg,image/jpg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0;pointer-events: none;" @change="openImageDialog($event, 'Cover Pic')" />
                        </label>
                      </div>
                    </div>
                    <span class="text-danger" ref="coverPicError"></span>
                  </div>
                </b-form>
              </div>
              <div class="mt-4">
                <b-btn variant="primary" class="pull-left" @click="clubAdd()" :disabled="cvLoadingStatus || cvImageLoadingStatus || cvImageError">
                  <b-spinner small class="ml-4 mr-4" v-if="cvLoadingStatus || cvImageLoadingStatus" label="Spinning">
                  </b-spinner>
                  <span v-else>{{cvClubCreateBtn}}</span>
                </b-btn>
              </div>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />
    <AlertBox :key="showUpgradeAlertBox" v-if="showUpgradeAlertBox" :propAlertTitle="alertTitle" :propAlertDesc="alertDesc" :propOkBtnText="okText" :propCancelBtnText="cancelText" @emitCloseAlertModel="showUpgradeAlertBox = false" @emitConfirmAlertModel="goToPlanList()"/>

    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </div>
</template>
<style scoped lang="scss">
@media (max-width: 479px) {
  .profile-feed-items li{
    margin: 0px !important;
  }
}
.disabled {
  pointer-events:none;
  opacity:0.6;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>

import { socialvue } from "../../../../config/pluginInit"
import { Clubs } from "../../../../FackApi/api/club.js"
import { Images } from "../../../../FackApi/api/image.js"
import ApiResponse from "../../../../Utils/apiResponse.js"
import { ValidationProvider } from "vee-validate"
import CropImage from "../../../../components/cropImage.vue"
import { Organisations } from "../../../../FackApi/api/organisation.js"
import AlertBox from "../../../../components/AlertBox.vue"
import Multiselect from "vue-multiselect"
import userPermission from "../../../../Utils/user_permission"

export default {
  name: "ProfileEdit",
  components: {
    ValidationProvider,
    CropImage,
    Multiselect,
    AlertBox
  },
  data () {
    return {
      apiName: "club_add",
      cvSelectLabel: "Select ",
      cvLoadingStatus: false,
      cvImageLoadingStatus: false,
      cvProfileInfo: "Profile",
      cvAbout: "About",
      cvContact: "Contact",
      cvEditRoles: "Manage Roles",
      cvManageUsers: "Manage Users",
      cvSubmitBtn: "Edit",
      cvClubNameLabel: "Club Name",
      cvClubCreateBtn: "Create Club",
      cvUnivDescLabel: "Description",
      cvClubPhone: "Phone",
      cvClubEmail: "Email",
      cvClubProfilePic: "Profile Pic",
      cvClubCoverPic: "Cover Pic",
      cvClubWebsiteLabel: "Website",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Club",
      cvImageError: false,
      cvImageExtensionErrorMsg: " Only jpeg, webp and png is supported.",
      cvImageSizeErrorMsg: " Image size must be less than 300 kb.",
      vmClubFormData: Object.assign({}, this.initClubData()),
      vmClubProPicImage: Object.assign({}, this.initImageData()),
      vmClubCoverPicImage: Object.assign({}, this.initImageData()),
      vmProfilePic: "",
      vmCoverPic: "",
      vmClubProfileImage: Object.assign({}, this.initImageData()),
      propCropImageDialogOpen: false,
      fileSrc: "",
      updateImageType: null,
      propStencilSize: null,
      propCropDialogSize: "md",
      owner_module_obj_id: null,
      cvUnivOptions: [],
      cvCreateAsOption: [ "Admin", "University" ],
      vmCreateAs: "Admin",
      showUpgradeAlertBox: false,
      alertDesc: "Please upgrade to Gide Plus",
      alertTitle: "Club",
      okText: "Upgrade",
      cancelText: "Not now",
      gideplus: 0,
      plus_or_not: false
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    this.owner_module_obj_id = this.$route.params.owner_module_obj_id
    this.myPlan = await this.$store.getters["Paygw/myPlanState"]
    this.loadRequiredData()
    await this.organisationList()
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  methods: {
    orgNameWithCityCountry (event) {
      return `${event.org_name} ${event.org_city ? "," + event.org_city : ""} ${event.org_country ? "," + event.org_country : ""}`
    },
    /**
     * goToPlanList
    */
    async goToPlanList () {
      this.$router.push("/subscription_plans")
    },
    /**
     * organisationList
     */
    async organisationList (searchString = null) {
      let filter = {}

      if (searchString && searchString.trim().length >= 2) {
        filter.search = searchString.trim()
      }

      filter.limit = 5
      filter.fields = [ "org_name", "org_city", "org_country" ]
      filter.type = ["UNIV"]

      const list = await Organisations.organisationListSearch(this, filter)
      if (list.resp_status) {
        this.cvUnivOptions = list.resp_data.data
      }
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * initImageData
     */
    initClubData () {
      return {
        club_name: "",
        images: [],
        owner_module_obj_id: this.owner_module_obj_id
      }
    },
    /**
     * initImageData
     */
    initImageData () {
      return {
        image: "",
        image_type: ""
      }
    },
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      socialvue.index()
    },
    /**
     * clubAdd
     */
    async clubAdd () {
      let valid = await this.$refs.clubForm.validate()
      if (!valid) {
        return
      }
      try {
        this.cvLoadingStatus = true
        this.vmClubFormData.owner_module_obj_id = this.owner_module_obj_id

        if (this.plus_or_not === false) {
          this.gideplus = 0
        }
        else {
          this.gideplus = 1
        }

        this.vmClubFormData.is_gide_plus = this.gideplus
        let clubAddResp = await Clubs.clubAdd(this, this.vmClubFormData)

        if (!clubAddResp.resp_status && clubAddResp.resp_code === "ERR_USER_NOT_SUBSCRIBED") {
          // If We give club creation access then check for subscription and throw error
          this.showUpgradeAlertBox = true
          return false
        }

        ApiResponse.responseMessageDisplay(this, clubAddResp)

        if (clubAddResp.resp_data && clubAddResp.resp_data.club_id) {
          this.cvLoadingStatus = false
          await this.imageAdd(clubAddResp.resp_data.club_id)
          await this.$router.push("/club_edit/" + clubAddResp.resp_data.club_id + "?about=true")
        }
      }
      catch (err) {
        console.error("Exception occurred at clubAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * imageAdd
     */
    async imageAdd (clubId) {
      if (this.cvImageError) {
        return
      }
      try {
        this.cvImageLoadingStatus = true
        let imageObj = {
          "module_name": "CLUB",
          "module_id": clubId,
          "image": []
        }

        if (this.vmClubProPicImage.image) {
          imageObj.image.push(this.vmClubProPicImage)
        }

        if (this.vmClubCoverPicImage.image) {
          imageObj.image.push(this.vmClubCoverPicImage)
        }

        if (imageObj.image.length < 1) {
          return false
        }

        const imageUpdateResp = await Images.imageProfileAdd(this, imageObj)
        await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
        if (imageUpdateResp && !imageUpdateResp.resp_status) {
          this.cvImageLoadingStatus = false
          return false
        }
        this.vmUnivProPicImage = Object.assign({}, this.initImageData())
        this.vmUnivCoverPicImage = Object.assign({}, this.initImageData())
        imageObj = {}
      }
      catch (err) {
        console.error("Exception occurred at imageAdd() and Exception:", err.message)
      }
      finally {
        this.cvImageLoadingStatus = false
      }
    },
    /**
     * openImageDialog
     *
     */
    openImageDialog (e, imageType) {
      this.updateImageType = imageType
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }
      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 300 * 1024 // 300 KB in bytes
        if (!allowedExtension.includes(image.type)) {
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.toastVariant = "danger"
            this.showToast = true
          }
          return false
        }
        if (imageType === "Profile Pic") {
          this.propStencilSize = {
            "width": 150,
            "height": 150
          }
          this.propCropDialogSize = "md"
        }
        else {
          this.propStencilSize = {
            "width": 970,
            "height": 240
          }
          this.propCropDialogSize = "xl"
        }
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
     * onChangeProfileImage
     */
    onChangeProfileImage (e, imageType) {
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          if (imageType === "Profile Pic") {
            this.vmProfilePic = await reader.result
          }
          else {
            this.vmCoverPic = await reader.result
          }
        }
        // reader.readAsDataURL(image)
      }
      catch (err) {
        console.error("Exception occurred at onChangeProfilePic() and Exception:", err.message)
      }
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      if (this.updateImageType === "Profile Pic") {
        this.vmProfilePic = img
      }
      else {
        this.vmCoverPic = img
      }
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      let img = imgObj.imgFile
      let orgImg = imgObj.orgImage
      let imageType = this.updateImageType
      this.onChangeProfileImage(img, imageType)
      if (imageType === "Profile Pic") {
        this.vmClubProPicImage = Object.assign({}, this.initImageData())
        this.vmClubProPicImage.image = {
          "img": img,
          "name": orgImg.name
        }
        this.vmClubProPicImage.image_type = "profile_pic"
      }
      else {
        this.vmClubCoverPicImage = Object.assign({}, this.initImageData())
        this.vmClubCoverPicImage.image = {
          "img": img,
          "name": orgImg.name
        }
        this.vmClubCoverPicImage.image_type = "cover_pic"
      }
    }
  }
}
</script>
